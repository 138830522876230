<template>
  <el-dialog
    title="巡田详情"
    top="60px"
    append-to-body
    width="1200px"
    :visible="patrolVisible"
    @close="closeMe"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <div style="background: #fff" v-if="patrolVisible">
      <div style="width: 100%; height: 625px; position: relative">
        <patrolDetailCom :detailId="detailId"></patrolDetailCom>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import patrolDetailCom from './patrolDetailCom.vue';
export default {
  components: {
    patrolDetailCom
  },
  props: {
    patrolVisible: true,
    detailId: ''
  },
  data() {
    return {};
  },
  computed: {},
  created() {},

  mounted() {},

  methods: {
    closeMe() {
      this.$emit('update:patrolVisible', false);
    }
  }
};
</script>
<style lang="scss" scoped></style>

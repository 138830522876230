<template>
  <div class="list-wrap">
    <el-dialog
      title="请选择打卡点"
      class="dg"
      :visible="showMarkerListDg"
      top="160px"
      append-to-body
      width="636px"
      @close="closeMe"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <div class="list-wrap" v-if="markerListData && markerListData.length">
        <div class="item-wrap" v-for="markerDetailData in markerListData" :key="markerDetailData.id" @click="watchClockDetail(markerDetailData)">
          <div class="left">
            <img :src="clockImg(markerDetailData.detail)" alt="" />
            <span
              :class="[
                markerDetailData.conclusion == 1 || markerDetailData.conclusion == 2 || markerDetailData.conclusion == 20 ? '' : 'exception-color',
                'icon iconfont',
                markerDetailData.conclusion == 1 || markerDetailData.conclusion == 2 || markerDetailData.conclusion == 20
                  ? 'icon-zuowu'
                  : markerDetailData.conclusion == 3 || markerDetailData.conclusion == 4
                  ? 'icon-shifeiliuliang'
                  : markerDetailData.conclusion == 5
                  ? 'icon-bingchonghai'
                  : markerDetailData.conclusion == 6
                  ? 'icon-yujing'
                  : markerDetailData.conclusion == 7
                  ? 'icon-jiankongshebei'
                  : ''
              ]"
            ></span>
          </div>
          <div class="right">
            <div class="title">
              <span
                :class="[
                  'noraml-color',
                  markerDetailData.conclusion == 1 || markerDetailData.conclusion == 2 || markerDetailData.conclusion == 20 ? '' : 'exception-color'
                ]"
                >{{ markerDetailData.farmWorkConclusionName }}</span
              >
              <span class="icon iconfont icon-xiayiye"></span>
            </div>
            <div class="upload-time">上传时间：{{ transDateTime(markerDetailData.actionTime) }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <punchDetailCom :punchDetailData="punchDetailData" :punchVisible.sync="punchDgShow"></punchDetailCom>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import punchDetailCom from './punchDetail.vue';
export default {
  props: {
    markerListData: {
      type: Array,
      default: []
    },
    showMarkerListDg: {
      type: Boolean,
      default: false
    }
  },
  components: {
    punchDetailCom
  },
  data() {
    return {
      punchDgShow: false,
      markerDetailData: null,
      punchDetailData: null
    };
  },
  methods: {
    transDateTime(val) {
      return dayjs(val).format('YYYY.MM.DD HH:mm');
    },
    clockImg(detail) {
      if (detail) {
        return JSON.parse(detail)[0];
      } else {
        return 'https://prodoss.bjznyjny.com/article/20230613/714c6b55cc444160b3b92b80458d07f2.png';
      }
    },
    watchClockDetail(detail) {
      this.punchDgShow = true;
      if (detail.detail) {
        let imgs = JSON.parse(detail.detail);
        detail.imgList = imgs;
      } else {
        detail.imgList = [];
      }
      this.punchDetailData = detail;
    },
    closeMe() {
      this.$emit('update:showMarkerListDg', false);
    }
  }
};
</script>
<style scoped lang="scss">
.list-wrap {
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}
.item-wrap {
  width: 273px;
  height: 78px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &:nth-child(2n + 1) {
    margin-right: 10px;
  }
  .left {
    width: 72px;
    height: 54px;
    background: #000000;
    border-radius: 8px;
    // border: 1px solid #ffffff;
    position: relative;
    img {
      width: 100%;
      height: 54px;
      border-radius: 8px;
    }
    .icon {
      position: absolute;
      top: 0px;
      right: 4px;
      color: #fff;
      font-size: 11px;
    }
    .exception-color {
      color: #fa8f00;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 54px;
    box-sizing: border-box;
    padding: 3px 0 3px 10px;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333;
      .exception-color {
        color: #fa8f00;
      }
    }
    .upload-time {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999;
    }
  }
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrap"},[_c('el-dialog',{staticClass:"dg",attrs:{"title":"请选择打卡点","visible":_vm.showMarkerListDg,"top":"160px","append-to-body":"","width":"636px","destroy-on-close":"","close-on-click-modal":false},on:{"close":_vm.closeMe}},[(_vm.markerListData && _vm.markerListData.length)?_c('div',{staticClass:"list-wrap"},_vm._l((_vm.markerListData),function(markerDetailData){return _c('div',{key:markerDetailData.id,staticClass:"item-wrap",on:{"click":function($event){return _vm.watchClockDetail(markerDetailData)}}},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":_vm.clockImg(markerDetailData.detail),"alt":""}}),_c('span',{class:[
              markerDetailData.conclusion == 1 || markerDetailData.conclusion == 2 || markerDetailData.conclusion == 20 ? '' : 'exception-color',
              'icon iconfont',
              markerDetailData.conclusion == 1 || markerDetailData.conclusion == 2 || markerDetailData.conclusion == 20
                ? 'icon-zuowu'
                : markerDetailData.conclusion == 3 || markerDetailData.conclusion == 4
                ? 'icon-shifeiliuliang'
                : markerDetailData.conclusion == 5
                ? 'icon-bingchonghai'
                : markerDetailData.conclusion == 6
                ? 'icon-yujing'
                : markerDetailData.conclusion == 7
                ? 'icon-jiankongshebei'
                : ''
            ]})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_c('span',{class:[
                'noraml-color',
                markerDetailData.conclusion == 1 || markerDetailData.conclusion == 2 || markerDetailData.conclusion == 20 ? '' : 'exception-color'
              ]},[_vm._v(_vm._s(markerDetailData.farmWorkConclusionName))]),_c('span',{staticClass:"icon iconfont icon-xiayiye"})]),_c('div',{staticClass:"upload-time"},[_vm._v("上传时间："+_vm._s(_vm.transDateTime(markerDetailData.actionTime)))])])])}),0):_vm._e()]),_c('punchDetailCom',{attrs:{"punchDetailData":_vm.punchDetailData,"punchVisible":_vm.punchDgShow},on:{"update:punchVisible":function($event){_vm.punchDgShow=$event},"update:punch-visible":function($event){_vm.punchDgShow=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <el-dialog
    title="选择地图"
    :visible="mapVisible"
    width="40%"
    :modal="false"
    @close="closeMe"
    class="map-dia"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <div class="search-box">
      <div class="label">关键字搜索</div>
      <el-input v-model="input" placeholder="请输入内容" ref="tipinput" id="tipinput"></el-input>
    </div>
    <div v-if="currentAddress" class="flex-between">
      <div> 当前选中: {{ currentAddress }} </div> <el-button size="small" type="primary" @click="onSubmit">确定</el-button>
    </div>
    <div id="amap"></div>
  </el-dialog>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import { amapRegeo } from '../api/api';

export default {
  props: {
    mapVisible: false
  },
  data() {
    return {
      map: '',
      input: '',
      auto: null,
      placeSearch: null,
      markers: [],
      currentData: null
    };
  },
  computed: {
    currentAddress() {
      if (!this.currentData) {
        return null;
      }
      let arr = [];

      if (this.currentData.pname.length) {
        arr.push(this.currentData.pname);
      }
      if (this.currentData.cityname.length) {
        arr.push(this.currentData.cityname);
      }
      if (this.currentData.adname.length) {
        arr.push(this.currentData.adname);
      }
      if (this.currentData.address.length) {
        arr.push(this.currentData.address);
      }
      return arr.join('/');
    }
  },
  created() {},
  watch: {
    mapVisible(val) {
      (this.map = ''), (this.input = ''), (this.auto = null), (this.placeSearch = null), (this.markers = []), this.initMap();
    }
  },
  mounted() {},

  methods: {
    select(e) {
      console.log(e, '###');
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name); //关键字查询查询
    },
    //
    closeMe() {
      this.$emit('update:mapVisible', false);
    },
    clickMapHandler(item) {
      amapRegeo({ lat: item.lnglat.lat, lng: item.lnglat.lng })
        .then((e) => {
          this.currentData = {
            pname: e.province,
            cityname: e.city,
            adname: e.county,
            address: e.township,
            location: [item.lnglat.lng, item.lnglat.lat],
            lng: item.lnglat.lng,
            lat: item.lnglat.lat
          };
        })
        .catch((error) => {});
    },
    onSubmit() {
      this.$emit('select', this.currentData);
    },
    initMap() {
      AMapLoader.load({
        key: '30c480db1ae109bf214b2dda752cfcbb', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.PlaceSearch', 'AMap.AutoComplete'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map('amap', {
            //设置地图容器id
            resizeEnable: true,
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 14 //初始化地图级别
            // center: [121.473667, 31.230525] //初始化地图中心点位置
          });
          this.map.on('click', this.clickMapHandler);
          this.auto = new AMap.AutoComplete({
            input: 'tipinput' // 使用联想输入的input的id
          });

          this.placeSearch = new AMap.PlaceSearch({
            map: this.map
          }); //构造地点查询类
          this.auto.on('select', this.select);
          this.map.on('click', this.clickMapHandler);

          this.placeSearch.on('markerClick', (e) => {
            this.currentData = e.data;
            this.selectMark = e;
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
};
</script>
<style>
.amap-sug-result {
  z-index: 9999 !important;
}
</style>

<style lang="scss" scoped>
#amap {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 400px;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
}

.search-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;

  .label {
    width: 100px;
  }
}
</style>

<template>
  <div class="detail-container">
    <div id="map"></div>
    <div :class="['operate-wrap', detailType == 2 ? 'bg-color' : '']">
      <span v-if="detailType == 2" class="reback-icon icon iconfont icon-chexiaofanhui" @click="backMap"></span>
      <template v-if="rsList && rsList.length">
        <div class="switch-tips">
          <div class="flex-row-switch">
            <img class="icon" src="@/assets/growth_icon.png" alt="" />
            <el-switch v-model="growthOpen" @change="growthShowChange(growthOpen)" active-color="#13ce66"> </el-switch>
          </div>
          <div class="flex-row-switch">
            <img class="icon" src="@/assets/quanding_red_icon.png" alt="" />
            <el-switch v-model="growthLineOpen" active-color="#13ce66" @change="growthLineShowChange(growthLineOpen)"> </el-switch>
          </div>
          <el-dropdown trigger="click" @command="watchRsDetail">
            <div class="calendar-wrap">
              <span class="icon iconfont icon-riqi"></span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :class="['date-item', selRsId == item.id ? 'active' : '']" v-for="item in rsList" :key="item.id" :command="item">
                <span class="icon iconfont icon-yunliang-youyun1" :style="'color:' + item.iconColor"></span>
                <span class="cloud-val">{{ item.iconContext }}</span>
                <span class="date-val">{{ transDate(item.remoteSenseTime) }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </div>
    <div class="zhangshi-tips" v-if="rsList && rsList.length">
      <img src="@/assets/zhangshi_tip.png" alt="" />
    </div>
    <div class="zoom-tool-wrap">
      <div class="tool-btn" @click="zoomAdd">
        <span class="zn-zoom-icon icon iconfont icon-jiahao"></span>
      </div>
      <div class="tool-btn" @click="zoomCut">
        <span class="zn-zoom-icon icon iconfont icon-jianhao"></span>
      </div>
    </div>
    <punchDetailCom :punchDetailData="punchDetailData" :punchVisible.sync="punchDgShow"></punchDetailCom>
    <markerListCom :markerListData="markerListData" :showMarkerListDg.sync="showMarkerListDg"></markerListCom>
  </div>
</template>

<script>
import * as L from 'leaflet';
import { googleMap, gaodeMap } from '@/config/map.js';
import * as utils from '@/utils/utils.js';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '@/config/leaflet.animatedmarker';
import 'leaflet-polylinedecorator';
import 'leaflet/dist/leaflet.css';
import { getRequest } from '@/api/api';
import axios from 'axios';
import gjHead from '@/images/gj_head.png';
import anchorDefaultIcon from '@/images/anchor_default_icon.png';
import anchorFinisheIcon from '@/images/anchor_finished_icon.png';
import punchDetailCom from './punchDetail.vue';
import markerListCom from './markerList.vue';
import dayjs from 'dayjs';
export default {
  components: {
    punchDetailCom,
    markerListCom
  },
  props: {
    detailId: '',
    detailType: {
      type: Number | String,
      default: 1
    }, // 1: 巡田详情 2: 地图模式中的巡田详情
    fullScreenFlag: false
  },
  data() {
    return {
      map: '',
      input: '',
      placeSearch: null,
      markerListData: [],
      showMarkerListDg: false,
      currentData: null,
      centerLat: 33.446385,
      centerLon: 114.071285,
      polygonLineColor: '#B2FFB2', // 绘制的多边形的边框颜色
      polygonFillColor: '#8DFF9B', // 绘制的多边形的填充颜色
      growthLineOpen: true,
      growthOpen: true, // 作物长势
      mapInfo: { lat: '33.4564', lng: '114.0848' },
      landData: null,
      polygon: null,
      selRsId: null,
      selRsItem: {},
      rsList: null,
      rsLayerArr: [],
      rsLayerGroup: null,
      getJsonData: null,
      loadIng: false,
      routeLineArr: [],
      realRouteLineArr: [],
      decoratorArr: [],
      animatedMarkerArr: [],
      punchDgShow: false, // 打卡弹窗
      punchDetailData: null
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.initMap();
  },
  watch: {
    fullScreenFlag(val) {
      this.fullScreen = val;
      this.resetMapSize();
    },
    detailId() {
      this.clearMapLayers();
      setTimeout(() => {
        this.getPatrolDetail();
      }, 500);
    }
  },
  methods: {
    resetMapSize() {
      this.$nextTick(() => {
        this.map && this.map.invalidateSize();
      });
    },
    transDate(val) {
      if (val) {
        return dayjs(val).format('YYYY.MM.DD');
      }
      return '-';
    },
    clearMapLayers() {
      this.polygonGroup && this.map.removeLayer(this.polygonGroup);
      this.anchorMarkerGroup && this.map.removeLayer(this.anchorMarkerGroup);
      this.clockMarkerGroup && this.map.removeLayer(this.clockMarkerGroup);
      this.simpleMarkerGroup && this.map.removeLayer(this.simpleMarkerGroup);
      this.realRouteLine && this.map.removeLayer(this.realRouteLine);
      this.polygonGroup = null;
      this.anchorMarkerGroup = null;
      this.clockMarkerGroup = null;
      this.simpleMarkerGroup = null;
      this.removeGj();
    },
    removeGj() {
      this.gjStop();
      this.routeLineArr.forEach((item) => {
        item && this.map.removeLayer(item);
      });
      this.realRouteLineArr.forEach((item) => {
        item && this.map.removeLayer(item);
      });
      this.decoratorArr.forEach((item) => {
        item && item.removeFrom(this.map);
      });
      this.animatedMarkerArr.forEach((item) => {
        item && item.remove();
      });
      this.routeLineArr = [];
      this.realRouteLineArr = [];
      this.decoratorArr = [];
      this.animatedMarkerArr = [];
    },
    gjStop() {
      if (this.animatedMarkerArr.length) {
        this.animatedMarkerArr.forEach((item) => {
          item.stop();
        });
      }
    },
    // 获取巡田详情
    getPatrolDetail() {
      let param = {
        plantingHistoryFarmingId: this.detailId
      };
      getRequest('/api/operate/common/planting/farming/view', param)
        .then((res) => {
          this.detailData = res;
          this.landId = res.landId;
          // this.landId = 226; // TODO
          // 获取地块列表
          this.getLandView();
          // 锚点地图渲染处理
          this.dealAnchors();
        })
        .catch((e) => {});
    },
    // 锚点展示处理
    dealAnchors() {
      let detail = this.detailData.detail;
      if (detail) {
        let detailObj = JSON.parse(detail);
        this.anchorLayers = [];
        this.parcelAnchorList = detailObj || [];
        console.log(this.parcelAnchorList);
        if (this.parcelAnchorList.length) {
          this.parcelAnchorList.forEach((item, index) => {
            item['id'] = index + 1;
            let icon = L.icon({
              iconSize: [24, 30],
              iconAnchor: [12, 15],
              iconUrl: item.state == 0 ? anchorDefaultIcon : anchorFinisheIcon
            });

            let marker = L.marker([item.lat, item.lon], { icon: icon }).addTo(this.map);
            this.anchorLayers.push(marker);
          });
          this.anchorMarkerGroup = L.layerGroup(this.anchorLayers);
          this.map.addLayer(this.anchorMarkerGroup);
        }
      }
    },
    getLandView() {
      getRequest('/api/customer/planting/land/view', { id: this.landId }).then((res) => {
        this.landData = res;
        this.showLands();
        //获取遥感记录列表
        this.recordsHistory();
      });
    },
    showLands() {
      let landData = this.landData;
      let boundaries = landData.boundaries || [];
      let polygonArr = [];
      boundaries.forEach((bound, index) => {
        let latLngs = [];
        bound.biasedBoundary.forEach((point) => {
          latLngs.push([point.latitude, point.longitude]);
        });
        this.polygon = L.polygon(latLngs, {
          color: this.polygonLineColor,
          fillColor: this.polygonFillColor,
          weight: 1,
          opacity: 1,
          fillOpacity: 0.3,
          _id: landData.id
        })
          .addTo(this.map)
          .on('click', (e) => {
            return;
          });
        if (index == 0) {
          let bounds = this.polygon.getBounds();
          let preLevel = this.map.getZoom();
          this.map.fitBounds(bounds);
          let newLevel = this.map.getZoom();
          this.initFitFlag = true;
          if (preLevel == newLevel) {
            this.zoomendAction();
          }
        }
        polygonArr.push(this.polygon);
      });
      this.polygonGroup = L.layerGroup(polygonArr);
      this.map.addLayer(this.polygonGroup);
    },
    recordsHistory() {
      let param = {
        landId: this.landId,
        pageNum: 1,
        pageSize: 7,
        remoteSenseType: 3
      };
      getRequest('/api/customer/planting/remote/sense/records/history', param)
        .then((res) => {
          let rsData = res;
          this.loadIng = false;
          if (rsData.rows.length) {
            this.rsList = rsData.rows;
            this.selRsItem = this.rsList.length && this.rsList[0];
            this.selRsId = this.selRsItem.id;
            this.renderImg();
          } else {
            // 若遥感图片已经渲染过，则删除后重新添加
            this.removeRsLayer();
            this.map.addLayer(this.polygon);
          }
        })
        .catch((err) => {
          this.loadIng = false;
        });
    },
    // 查看遥感
    watchRsDetail(item) {
      this.selRsItem = item;
      this.selRsId = item.id;
      this.renderImg();
    },
    // 渲染遥感的图片
    renderImg() {
      this.removeRsLayer();
      let rsObj = this.selRsItem;
      this.rsLayerArr = [];
      if (rsObj.images && rsObj.images.length > 0) {
        rsObj.images.forEach((item) => {
          let imageBounds = item.coordinateBiased;
          let imageUrl = item.remoteSenseImgUrl;
          let rsLayer = L.imageOverlay(imageUrl, imageBounds, { opacity: 1 }); //opacity是透明度
          this.rsLayerArr.push(rsLayer);
          this.map.addLayer(rsLayer);
          this.map.removeLayer(this.polygon);
        });
        this.rsLayerGroup = L.layerGroup(this.rsLayerArr);
        this.map.addLayer(this.rsLayerGroup);
      }
      if (rsObj.areaInfoUrl) {
        axios
          .get(rsObj.areaInfoUrl)
          .then((res) => {
            if (res.data) {
              this.getJsonData = res.data;
              this.renderZhangShiGeo();
            }
          })
          .catch((error) => {
            console.log(error, '$$$');
          });
      }
    },
    renderZhangShiGeo() {
      let style = {
        color: '#FA5555', //边框颜色
        weight: 1, //边框粗细
        opacity: 1, //透明度
        fillColor: 'transparent', //区域填充颜色
        fillOpacity: 0 //区域填充颜色的透明
      };
      if (this.geoLayer) {
        this.map.removeLayer(this.geoLayer);
      }
      this.geoLayer = L.geoJSON(this.getJsonData, { style: style });
      if (this.growthLineOpen) {
        this.map.addLayer(this.geoLayer);
      }
    },
    removeRsLayer() {
      this.rsLayerGroup && this.map.removeLayer(this.rsLayerGroup);
    },
    growthShowChange(isActive) {
      if (isActive) {
        this.rsLayerGroup && this.map.addLayer(this.rsLayerGroup);
        this.polygon && this.map.removeLayer(this.polygon);
      } else {
        // 删除遥感图层
        this.removeRsLayer();
        // 展示地块图层
        this.map.addLayer(this.polygon);
        // this.growthLineShowChange(false);
      }
    },
    // 是否展示长势线条
    growthLineShowChange(isActive) {
      this.growthLineOpen = isActive;
      if (isActive) {
        this.geoLayer && this.map.addLayer(this.geoLayer);
      } else {
        this.geoLayer && this.map.removeLayer(this.geoLayer);
      }
    },
    zoomAdd() {
      console.log(this.map, '-----------');
      this.map && this.map.setZoom(this.map.getZoom() + 1);
    },
    zoomCut() {
      this.map && this.map.setZoom(this.map.getZoom() - 1);
    },
    closeMe() {
      this.$emit('update:patrolVisible', false);
    },
    initMap() {
      // 地图初始化参数
      let initParam = {
        L,
        id: 'map',
        center: [this.centerLat, this.centerLon],
        mapType: googleMap,
        zoom: 12
      };
      // 初始化地图
      this.map = utils.initMap(initParam);
      this.getPatrolDetail();
      // 监听地图事件
      this.onMapDrawEvent();
      // zoom禁用是否切换
      this.toggleForbidZoom();
    },
    onMapDrawEvent() {
      // 当地图缩放改变时触发，在任何动画之后。
      this.map.on('zoomend', (e) => {
        setTimeout(() => {
          this.zoomendAction();
        }, 0);
      });
    },
    zoomendAction() {
      if (this.initFitFlag) {
        // 处理已巡过的路线
        this.dealPatrolLine();
        // 处理已打卡的marker
        this.dealMarkers();
        this.initFitFlag = false;
      }
    },
    dealMarkers() {
      let list = this.detailData.details;
      if (list && list.length) {
        this.editClockMarker(list);
      }

      // if (list && list.length) {
      //   let markersGroup = this.editMarkersGroup(list);
      //   markersGroup.forEach((item, index) => {
      //     this.editClockMarker(item);
      //   });
      // }
    },
    editMarkersGroup(list) {
      let groupList = [];
      let groupObj = {};
      list.forEach((item) => {
        let groupId = item.groupId;
        if (groupId) {
          if (groupObj[groupId]) {
            groupObj[groupId].push(item);
          } else {
            groupObj[groupId] = [item];
          }
        } else {
          groupList.push([item]);
        }
      });
      Object.keys(groupObj).forEach((key) => {
        if (key) {
          groupList.push(groupObj[key]);
        }
      });
      return groupList;
    },
    editClockMarker(list) {
      if (list && list.length) {
        let markersGroup = this.editMarkersGroup(list);
        let clockMarkerLayers = [];
        var imageUrl = 'https://prodoss.bjznyjny.com/article/20230613/714c6b55cc444160b3b92b80458d07f2.png';
        markersGroup.forEach((markers) => {
          let markerData = markers[0];
          let imgs = [];
          if (markerData.detail) {
            imgs = JSON.parse(markerData.detail);
            imageUrl = imgs[0];
          }
          markerData.imgList = imgs;
          let conclusion = markerData.conclusion;
          // 1: 田情正常 2: 长势良好 20: 其它情况
          let iconSpan = `<span class='marker-icon icon iconfont icon-zuowu'></span>`;
          if (conclusion == 3 || conclusion == 4) {
            // 3:干旱 4:内涝
            iconSpan = `<span class='marker-icon exception-marker-icon icon iconfont icon-shifeiliuliang'></span>`;
          } else if (conclusion == 5) {
            // 病虫害
            iconSpan = `<span class='marker-icon exception-marker-icon icon iconfont icon-bingchonghai'></span>`;
          } else if (conclusion == 6) {
            // 长势异常
            iconSpan = `<span class='marker-icon exception-marker-icon icon iconfont icon-yujing'></span>`;
          } else if (conclusion == 7) {
            // 设备损坏
            iconSpan = `<span class='marker-icon exception-marker-icon icon iconfont icon-jiankongshebei'></span>`;
          }
          let markerHtml =
            '<div class="marker-content">' +
            `<img class="marker-img ${conclusion != 1 && conclusion != 2 ? ' exception-marker-img' : ''}" src='${imageUrl}'/>` +
            iconSpan +
            `</div>`;
          let myMarker = L.marker([markerData.lat, markerData.lon], {
            icon: L.divIcon({
              iconUrl: imageUrl,
              className: '',
              iconSize: [40, 40],
              iconAnchor: [20, 20],
              html: markerHtml
            })
          }).addTo(this.map);
          myMarker.on('click', (e) => {
            this.markerClickTimer && clearTimeout(this.markerClickTimer);
            this.markerClickTimer = setTimeout(() => {
              if (markers.length > 1) {
                this.markerListData = markers;
                this.showMarkerListDg = true;
              } else {
                let detailData = markerData;
                this.imgTimer && clearTimeout(this.imgTimer);
                this.punchDgShow = true;
                this.punchDetailData = detailData;
              }
            }, 100);
          });
          clockMarkerLayers.push(myMarker);
        });
        this.clockMarkerGroup = L.layerGroup(clockMarkerLayers);
        this.map.addLayer(this.clockMarkerGroup);
      }
    },
    toggleForbidZoom() {
      if (this.animationIsEnd) {
        this.map.scrollWheelZoom.enable();
        this.map.dragging.enable();
        this.map.doubleClickZoom.enable();
      } else {
        this.map.scrollWheelZoom.disable();
        this.map.dragging.disable();
        this.map.doubleClickZoom.disable();
      }
    },
    dealPatrolLine() {
      let points = JSON.parse(this.detailData.points) || [];
      let dashedLatlngs = [];
      this.simpleMarkerArr = [];
      points.forEach((pointArr, index) => {
        if (pointArr.length) {
          let latlngs = [];
          if (index == 0) {
            dashedLatlngs.push([pointArr[pointArr.length - 1].lat, pointArr[pointArr.length - 1].lon]);
          } else {
            dashedLatlngs.push([pointArr[0].lat, pointArr[0].lon]);
            if (dashedLatlngs.length == 2) {
              this.renderStaticPatrolDashedLine(dashedLatlngs);
              dashedLatlngs = [];
              dashedLatlngs.push([pointArr[pointArr.length - 1].lat, pointArr[pointArr.length - 1].lon]);
            }
          }
          pointArr.forEach((obj, idx) => {
            let arr = [];
            if (idx == 0) {
              arr.push(obj.lat);
              arr.push(obj.lon);
              latlngs.push(arr);
            }
            if (idx > 0 && pointArr[idx].lat != pointArr[idx - 1].lat && pointArr[idx].lon != pointArr[idx - 1].lon) {
              arr.push(obj.lat);
              arr.push(obj.lon);
              latlngs.push(arr);
            }
          });
          if (latlngs.length == 1) {
            this.renderSimpleMarker(latlngs);
          } else {
            this.renderAnimatePatrolLine(latlngs);
          }
        }
      });
      this.lineAnimationStart();
    },
    // 渲染暂停后重新继续的轨迹线
    renderStaticPatrolDashedLine(dashedLatlngs) {
      // 虚拟轨迹线
      this.realRouteLine = L.polyline(dashedLatlngs, {
        weight: 5,
        color: '#FFFFFF',
        opacity: 0.7,
        dashArray: [5, 10]
      }).addTo(this.map);
    },
    // 轨迹只有一个点处理
    renderSimpleMarker(latlngs) {
      console.log(latlngs, 'latlngs====');
      var locationIcon = L.icon({
        iconSize: [20, 20],
        iconAnchor: [10, 10],
        iconUrl: gjHead
      });
      // 视图位置的icon标志
      let simpleMarker = L.marker(latlngs[0], { icon: locationIcon });
      this.simpleMarkerArr.push(simpleMarker);
      this.simpleMarkerGroup = L.layerGroup(this.simpleMarkerArr);
      this.map.addLayer(this.simpleMarkerGroup);
    },
    // 动态渲染巡田路线
    renderAnimatePatrolLine(latlngs) {
      // 轨迹线
      let routeLine = L.polyline(latlngs, {
        weight: 8,
        color: '#BFD2C2'
      }).addTo(this.map);
      // 实时轨迹线
      let realRouteLine = L.polyline([], {
        weight: 8,
        color: '#02B98F'
      }).addTo(this.map);
      // 轨迹方向箭头
      let decorator = L.polylineDecorator(routeLine, {
        patterns: [
          {
            repeat: 50,
            symbol: L.Symbol.arrowHead({
              pixelSize: 5,
              headAngle: 75,
              polygon: false,
              pathOptions: {
                stroke: true,
                weight: 2,
                color: '#FFFFFF'
              }
            })
          }
        ]
      }).addTo(this.map);
      var headIcon = L.icon({
        iconSize: [20, 20],
        iconAnchor: [10, 10],
        iconUrl: gjHead
      });
      var newLatlngs = [routeLine.getLatLngs()[0]];
      // 动态marker
      let animatedMarker = L.animatedMarker(routeLine.getLatLngs(), {
        interval: 200, // 默认为100mm
        autoStart: false,
        icon: headIcon,
        playCall: (latlng) => {
          newLatlngs.push(latlng);
          realRouteLine.setLatLngs(newLatlngs);
        },
        onEnd: (isEnd) => {
          if (isEnd) {
            this.animationIsEnd = isEnd;
            this.toggleForbidZoom();
          }
        }
      }).addTo(this.map);
      this.routeLineArr.push(routeLine);
      this.realRouteLineArr.push(realRouteLine);
      this.decoratorArr.push(decorator);
      this.animatedMarkerArr.push(animatedMarker);
    },
    // 巡田线路动画渲染开始
    lineAnimationStart() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.animatedMarkerArr.length) {
            this.animatedMarkerArr.forEach((item) => {
              item.start();
            });
          } else {
            this.animationIsEnd = true;
            this.toggleForbidZoom();
          }
        }, 400);
      });
    },
    backMap() {
      this.$emit('updateMapType', 2);
    }
  }
};
</script>
<style scoped>
#map >>> .marker-content {
  position: relative;
}
#map >>> .marker-img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  object-fit: cover;
}
#map >>> .exception-marker-img {
  border: 1px solid #fff24d;
}
#map >>> .marker-icon {
  position: absolute;
  top: 0;
  right: 2px;
  font-size: 8px;
  color: #ffffff;
}
#map >>> .exception-marker-icon {
  color: #fff24d;
}
</style>

<style lang="scss" scoped>
.detail-container {
  width: 100%;
  height: 100%;
  #map {
    height: 100%;
    width: 100%;
  }
  .operate-wrap {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;
  }
  .bg-color {
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    .reback-icon {
      font-size: 20px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.flex-row-switch {
  display: flex;
  flex-direction: row;
  width: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  .icon {
    width: 22px;
    height: 22px;
  }
}

.zoom-tool-wrap {
  position: absolute;
  left: 10px;
  bottom: 40px;
  z-index: 999;
  .tool-btn {
    display: block;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 5px;
    text-decoration: none;
    cursor: pointer;
  }
}
.zhangshi-tips {
  position: absolute;
  right: 10px;
  bottom: 40px;
  z-index: 999;
  img {
    width: 24px;
    height: 200px;
  }
}
.switch-tips {
  display: flex;
  align-items: center;
  .calendar-wrap {
    cursor: pointer;
    .icon {
      font-size: 22px;
      color: #ffffff;
    }
  }
}
.date-item {
  width: 166px;
  height: 40px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313746;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe6;
  &:last-child {
    border-bottom: none;
  }
  .icon {
    height: auto;
  }
  &.active {
    background: rgba(2, 185, 143, 0.1);
  }
}
</style>
